<template>
  <div class="excel-load flex flex-column card-header-divider">
    <div
      v-if="!loadStarted"
      class="excel-load__label display--flex justify-content-center"
    >
      <div class="excel-load__block">
        <div>1. Выберите файл для эталонного профиля</div>
        <custom-file-loader
          :showFileName="true"
          name="excel-loader"
          @loading="loadingAttempt"
          accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </div>
      <div class="excel-load__block">
        <div>2. Нажмите на кнопку загрузки и ожидайте</div>
        <button @click="load" class="btn btn-accent mr-10">Загрузить</button>
      </div>
    </div>

    <preloader v-if="loadStarted" />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import CustomFileLoader from "@/components/Forms/Fields/CustomFileLoader.vue";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";
import Preloader from "@/components/Technical/Preloader.vue";

export default {
  components: { CustomSelect2, CustomFileLoader, Preloader },
  name: "excel-load-row",
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore(),
      router = useRouter();
    const hideOptions = ref(true);
    let file = null;
    let loadStarted = false;

    const loadingAttempt = (files) => {
      file = files[0];
    };

    const load = () => {
      if (!file) {
        notify({
          type: "warning",
          title: "Необходимо выбрать файл",
        });
        return;
      }
      loadStarted = true;
      store
        .dispatch("references/loadReferencesExcel", {
          file: file,
        })
        .then(() => {
          router.push("/excel/references");
        });
    };

    return {
      hideOptions,
      loadingAttempt,
      load,
      loadStarted,
    };
  },
};
</script>

<style lang="scss" scoped>
.excel-load {
  &__block {
    width: 45%;
    padding: 15px;
    border: 2px solid #ebebeb;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .btn-accent {
      margin-top: 10px;
    }
  }

  &__block:not(:last-child) {
    border-right: none;
  }

  &__label {
    margin-bottom: 20px;
    font-weight: 400;

    @media (min-width: 974px) {
      padding-left: 10px;
    }
  }

  @media (max-width: 1200px) {
    &__label {
      flex-direction: column;
      align-items: center;
    }
    &__block {
      width: 100%;
    }
    &__block:not(:last-child) {
      border-right: 2px solid #ebebeb;
      border-bottom: none;
    }
  }

  &__row {
    .form-group:first-child {
      min-width: 50%;
    }

    .form-group {
      max-width: 50%;
    }
  }

  // .excel-load__example

  &__example {
    a {
      color: var(--main-color);
      font-weight: 300;
      font-size: 16px;
      cursor: pointer;
      padding: 6px;

      &:hover {
        color: var(--hover-color);
        border-color: var(--hover-color);
      }
    }
  }

  @media (max-width: 1024px) {
    &__row {
      flex-direction: column;
      align-items: flex-start;

      .form-group,
      .form-group:first-child {
        min-width: 100%;
        margin-bottom: 20px;
      }

      .form-group:not(:first-child) {
        align-items: center;
      }
    }

    .form-group > div {
      padding: 0;
    }

    &__example {
      margin-top: 15px;
    }
  }
}
</style>