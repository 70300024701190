<template>
  <div v-if="!isPreloader" class="table-responsive relations-table">
    <ag-grid-vue
      :columnDefs="columnDefs.value"
      :rowData="rowData"
      :rowHeight="70"
      :headerHeight="90"
      :enableCellTextSelection="true"
      :ensureDomOrder="true"
      :pagination="true"
      :paginationPageSize="100"
      :defaultColDef="defaultColDef"
      :suppressRowHoverHighlight="true"
      :suppressPaginationPanel="true"
      :suppressRowClickSelection="true"
      :suppressRowTransform="true"
      style="width: 100%"
      domLayout="autoHeight"
      @grid-ready="onGridReady"
    ></ag-grid-vue>
    <table-pagination
      :activeFilter="activeFilter"
      :gridApi="gridApi"
      :totalPages="gridApi ? gridApi.paginationGetTotalPages() : 0"
      :countOnPage="countOnPage"
    />
  </div>
  <div v-else class="table-preloader">
    <preloader />
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "ag-grid-community/dist/styles/ag-grid.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed, onBeforeMount } from "@vue/runtime-core";
import ButtonRenderer from "../../../components/Tables/CellRenderers/ButtonRenderer.vue";
import Preloader from "../../../components/Technical/Preloader.vue";
import ShortTextRenderer from "@/components/Tables/CellRenderers/ShortTextRenderer.vue";
import TablePagination from "../../../components/OtherElements/TablePagination.vue";
import router from "@/router";
import DeleteReference from "../../../components/Tables/CellRenderers/DeleteReference.vue";
import rusData from '../../../mixins/rusData';
import standartError from "../../../mixins/standartError";
import useErrors from "../../../mixins/useErrors";

export default {
  name: "file-references-table",
  components: {
    VPagination,
    AgGridVue,
    Preloader,
    TablePagination,
    ShortTextRenderer,
    ButtonRenderer,
    DeleteReference
  },
  setup() {
    const store = useStore(), 
 
      gridApi = ref(null),
      columnApi = ref(null),
      {getRusData} = rusData(), 
      { setErrors, clearCustomErrors } = useErrors();
    const countOnPage = ref(15);
    const activeFilter = ref(false);
   
    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    };
    const isPreloader = ref(false);
    const fileReferencesList = computed(
      () => store.state.references.fileReferencesList
    );
    
    const rowData = computed(() => {
      return fileReferencesList.value.map((file) => {
        return {
          id: file.id,
          name: file.name,
          createdAt: getRusData(file.created_at),
        };
      });
    });

    const checkFile = (id) => {
      let profile = fileReferencesList.value.filter((file) => {
        return file.id == id
      });
      const currentProfile = JSON.parse(JSON.stringify(profile[0]))
      isPreloader.value = true;
      store.dispatch("analytics/calculateProfile", currentProfile)
        .then(() => {
            isPreloader.value = false
            router.push("/references/profiles");
          })
        .catch(standartError(setErrors, clearCustomErrors, isPreloader));
     };

    const deleteItem = (id) => {
      store.dispatch("references/deleteReferenceExcel", id)
    };

    const columnDefs = reactive({
      value: [
        {
          field: "name",
          headerName: "Название",
          minWidth: 195,
          wrapText: true,
          cellRenderer: "ShortTextRenderer",
        },
        {
          field: "createdAt",
          headerName: "Дата загрузки",
          minWidth: 160,
        },
        {
          field: "",
          headerName: "",
          flex: 0,
          minWidth: 160,
          maxWidth: 160,
          cellRenderer: "ButtonRenderer",
          cellRendererParams: {
            callback: checkFile,
            text: "Рассчитать",
          },
        },
        {
          cellRenderer: "DeleteReference",
          cellRendererParams: {
            callback: deleteItem,
          },
        },
      ],
    });
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    onBeforeMount(() => {
      store.dispatch("references/getReferencesFilesList");
    });

    return {
      isPreloader,
      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi,
      countOnPage,
      activeFilter,
      getRusData,
    };
  },
};
</script>

<style lang="scss" scoped>
.filters {
  padding: 10px 8px;
}
</style>
